import React from "react";
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";
import { Box, Container, Grid } from "@mui/material";

import logo from "../assets/logo_legacoop_short.jpg";
import img1 from "../assets/3986448.jpg";
import img2 from "../assets/Impianti.jpg";
import img3 from "../assets/Staffetta.jpg";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.6,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

export default function P14_1() {
  return (
    <motion.div
      style={{
        height: "100%",
        backgroundImage: `linear-gradient(#1DE9B6, #E6EE9C)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "2rem",
        flexDirection: "column",
      }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <Container maxWidth="xl">
        <Grid container justifyContent={"center"}>
          <motion.p
            variants={childVariants}
            style={{
              color: "white",
              fontSize: "3em",
              marginBottom: "10px",
            }}
          >
            Lunga vita alla CER!
          </motion.p>

          <motion.div variants={childVariants} style={{ marginBottom: "20px" }}>
            <Grid container alignItems={"center"}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontStyle: "italic",
                    margin: 0,
                  }}
                >
                  "La cooperazione rappresenta un modello efficace per la
                  promozione delle comunità energetiche rinnovabili consente ai
                  cittadini di partecipare attivamente alla trasformazione del
                  sistema energetico verso una maggiore sostenibilità e equità."
                </p>
                <p style={{ margin: "4px" }}>
                  (Danilo Valenti – Presidente Legacoop Umbria)
                </p>
              </Grid>
              <Grid item xs={2} textAlign={"center"}>
                <Box
                  component={"img"}
                  src={logo}
                  sx={{ borderRadius: "50%", width: "120px" }}
                />
              </Grid>
            </Grid>
          </motion.div>

          <motion.div variants={childVariants} style={{ width: "100%" }}>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Box
                  component={"img"}
                  src={img1}
                  sx={{
                    borderRadius: "10px",
                    maxWidth: "100%",
                    maxHeight: "25vh",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component={"img"}
                  src={img2}
                  sx={{
                    borderRadius: "10px",
                    maxWidth: "100%",
                    maxHeight: "25vh",
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Box
                  component={"img"}
                  src={img3}
                  sx={{
                    borderRadius: "10px",
                    maxWidth: "100%",
                    maxHeight: "25vh",
                  }}
                />
              </Grid>
            </Grid>
          </motion.div>

          <motion.div variants={childVariants} style={{ width: "100%" }}>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <p>
                  Il modello democratico della cooperativa agevola la buona
                  convivenza
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  La proprietà dei mezzi di produzione:
                  <ul>
                    <li>
                      garantisce ricavi alla cooperativa, a prescindere dagli
                      incentivi
                    </li>
                    <li>
                      tutela dal "potere" decisionale dei membri con impianti
                      grandi
                    </li>
                    <li>
                      responsabilizza tutti i membri verso la propria comunità
                    </li>
                  </ul>
                </p>
              </Grid>
              <Grid item xs={3}>
                <p>
                  L’indivisibilità del patrimonio della cooperativa è una
                  garanzia per i membri attuali e futuri della Comunità.
                </p>
              </Grid>
            </Grid>
          </motion.div>

          <motion.p variants={childVariants}>
            Queste ed altre caratteristiche della forma cooperativa possono
            garantire una migliore durata nel tempo del progetto della Comunità
            Energetica Rinnovabile.
          </motion.p>
        </Grid>
      </Container>

      <Navigation back="/p14" forward="/p14_2" />
    </motion.div>
  );
}
