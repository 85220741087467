import React from "react";

import Navigation from "../components/Navigation";

import video_test from "../assets/video/Consumi_buoni.mp4";
import img from "../assets/consumiBuoni.png";
import LateralMenu from "../components/LateralMenu";

export default function P11() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu video={video_test} selected={5} />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <div>
          <img src={img} width={"100%"}></img>
        </div>
      </div>

      <Navigation back="/p10" forward="/p12" />
    </div>
  );
}
