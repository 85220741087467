import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { getCookie } from "../utils/manageCookies";

export default function ProtectedRoute({
  redirectPath = "https://certrude.it/accedi",
  children,
}) {
  const [auth, setAuth] = useState();

  useEffect(() => {
    const isAuthorised = async () => {
      const token = getCookie("token");
      if (token) {
        //console.log(`trovato token ${token}`)
        //check expiry date and, if successful, request user data
        const { exp, email, sessionID } = jwt_decode(token);
        //console.log(exp, username, sessionID);
        if (Date.now() >= exp * 1000) {
          console.log("Sessione scaduta");
          toast.error("Sessione scaduta");
          setAuth(false);
          //return undefined;
        } else {
          //verify auth server-side
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/auth-tutor`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ token }),
              }
            );

            const data = await response.json();
            console.log(data);
            setAuth(data.success);
          } catch (e) {
            if (!e?.response) {
              //setErrMsg('Il server non risponde. Prova più tardi')
              toast.error("Il server non risponde. Prova più tardi");
              console.log(`${e}: Il server non risponde. Prova più tardi`);
              setAuth(false);
            }
          }
        }
      } else {
        console.log("Nessuna sessione presente");
        toast.error("Nessuna sessione presente. Crea una nuova sessione");
        setAuth(false);
      }
    };

    //PER TESTING IN LOCALE COMMENTA QUI
    isAuthorised();
    //PER TESTING SCOMMENTA QUI
    //setAuth(true);
  }, []); //qui c'era auth ma non sembra servire.

  if (auth === undefined) return null;

  if (!auth) {
    console.log("Not authorised");
    //return <Navigate to={redirectPath} replace />;
    window.location.href = redirectPath;
  }
  console.log("Authorised");
  return children ? children : <Outlet />;
}
