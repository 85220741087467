import { Box, Grid } from "@mui/material";
import React from "react";

function CoopCard({ icon, title = "", onClick = () => {} }) {
  return (
    <Grid
      container
      sx={{
        borderRadius: "20px",
        border: "1px solid #AB2B36",
        width: "100%",
        height: "250px",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
        overflow: "hidden",
        cursor: "pointer",
        boxShadow: "4px 4px 15px 3px rgba(0,0,0,0.3)",
        ":hover": {
          border: "2px solid #AB2B36",
          boxShadow: "4px 4px 15px 2px rgba(0,0,0,0.5)",
        },
      }}
      component="div"
      onClick={onClick}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="150px"
      >
        <Box
          component={"img"}
          src={icon}
          sx={{
            maxWidth: "96%",
            maxHeight: "96%",
          }}
        />
      </Box>
      <Box
        display="flex"
        bgcolor={"#AB2B36"}
        flex={2}
        width={"100%"}
        alignItems="center"
        justifyContent={"center"}
        color={"#FFF"}
        textAlign={"center"}
      >
        <p style={{ paddingLeft: "8px", paddingRight: "8px" }}>{title}</p>
      </Box>
    </Grid>
  );
}

export default CoopCard;
