import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [email, setEmail] = useState();
  const [sessionID, setSessionID] = useState();
  const [token, setToken] = useState();

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        sessionID,
        setSessionID,
        token,
        setToken,
      }}
    >
      {children ? children : <Outlet />}
    </AuthContext.Provider>
  );
};
