import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/Wavy_Eco-08_Single-02.jpg";
function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Sostenibilità e attenzione per le generazioni future
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            La CER cooperativa è sostenibile e attenta alle esigenze delle
            generazioni future:
          </p>
          <ul>
            <li>
              nell’impresa cooperativa la parte destinata a riserve indivisibili
              (quelle che per legge non possono essere ripartite tra i soci
              neppure in caso di scioglimento) è molto più consistente rispetto
              a quella prevista per le società di capitali;
            </li>
            <li>
              questo significa che una CER in forma cooperativa tende a
              rafforzare il proprio patrimonio e questo la rende più
              sostenibile, più longeva e più intergenerazionale;
            </li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
