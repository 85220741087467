// Read the cookie
export function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

// Set the cookie
export function setCookie(key, value) {
  document.cookie = `${key}=${value};domain=.certrude.it;path=/`;
}
