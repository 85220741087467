import React from "react";

import Navigation from "../components/Navigation";

import video_test from "../assets/video/Partecipanti.mp4";
import LateralMenu from "../components/LateralMenu";

export default function P9() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu video={video_test} selected={2} />
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <ul style={{ color: "#333333", fontSize: "1.5em", fontWeight: 100 }}>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            un privato cittadino o un condominio
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            una Piccola Media Impresa o una cooperativa
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            un ente del terzo settore (come le associazioni), ente religioso,
            ente di ricerca o di protezione ambientale
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            un ente territoriale o autorità locale (come Comune, USL, …)
          </li>
        </ul>
      </div>

      <Navigation back="/p8" forward="/p9coop" />
    </div>
  );
}
