import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/5209019.jpg";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Finalità economiche, ambientali e sociali
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            La CER cooperativa persegue finalità economiche, ambientali e
            sociali:
          </p>
          <ul>
            <li>
              la cooperativa è un’impresa senza scopo di lucro basata sullo
              scambio mutualistico tra soci e cooperativa, che si concretizza in
              vantaggi anche di natura non economica;
            </li>
            <li>
              questo significa che il fine ultimo di una CER cooperativa non è
              il profitto, ma il raggiungimento di benefici economici,
              ambientali e sociali, come il contrasto alla povertà energetica,
              la produzione di energia pulita, l’autonomia di
              approvvigionamento, coesione, il rafforzamento della comunità,
              etc.;
            </li>
            <li>
              gli utili di un’impresa cooperativa vengono quasi interamente
              reinvestiti nello sviluppo della cooperativa stessa e nel
              rafforzamento del suo patrimonio;
            </li>
            <li>
              di conseguenza, gli utili di una CER cooperativa possono essere
              investiti nello sviluppo di servizi collettivi a beneficio
              dell’intera comunità interessata.
            </li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
