import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/scuola2.jpg";
function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Forte legame col territorio
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            La CER cooperativa crea un forte legame con il territorio:
          </p>
          <ul>
            <li>
              la comunità energetica rinnovabile costituita in forma cooperativa
              è uno strumento virtuoso e strutturato di coesione territoriale,
              che coinvolge i cittadini nei processi legati alla transizione
              energetica;
            </li>
            <li>
              favorisce la creazione dei rapporti fra soggetti pubblici e
              privati per perseguire interessi comuni e collettivi;
            </li>
            <li>
              consente la redistribuzione della ricchezza prodotta migliorando
              la qualità sociale, ambientale ed economica delle comunità locali.
            </li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
