import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/20944720.jpg";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Soggetto autonomo imprenditoriale
      </h3>

      <p style={{ marginBottom: "20px" }}>
        La CER cooperativa è un soggetto autonomo imprenditoriale basato sulla
        proprietà condivisa:
      </p>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <ul>
            <li>
              la cooperativa è un’impresa con personalità giuridica e autonomia
              patrimoniale;
            </li>
            <li>
              questo significa che in una CER cooperativa non esistono profili
              di responsabilità personale dei soci e di coloro che agiscono o
              assumono obbligazioni in nome e per conto della cooperativa;
            </li>
            <li>
              il patrimonio di un’impresa cooperativa è di proprietà comune dei
              soci in egual misura;
            </li>
            <li>
              una CER costituita in forma imprenditoriale consente una gestione
              degli impianti più strutturata, e agevola l’accesso agli strumenti
              finanziari disponibili.
            </li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
