import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/mutualita.png";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Inclusività
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            La CER cooperativa è inclusiva e consente la partecipazione a tutti
            i consumatori ubicati nel perimetro individuato, compresi quelli a
            basso reddito o vulnerabili:
          </p>
          <ul>
            <li>
              la cooperativa è un’impresa inclusiva basata sul principio della
              “porta aperta”;
            </li>
            <li>
              questo significa che per aderire ad una CER cooperativa è
              sufficiente trovarsi nel perimetro individuato e versare una
              minima quota richiesta;
            </li>
            <li>
              nell’impresa cooperativa non esiste un numero predefinito di
              quote; pertanto, per entrare a far parte di una CER in forma
              cooperativa non occorre acquistare una quota da un altro socio;
              allo stesso modo, per uscire, non è necessario vendere la propria
              quota, che sarà semplicemente rimborsata;
            </li>
            <li>
              per questo motivo, si dice che l’impresa cooperativa è un’impresa
              a capitale variabile, poiché varia in base alla base sociale e non
              è predefinito;
            </li>
            <li>
              questa caratteristica della variabilità del capitale agevola
              l’ingresso dei nuovi soci, anche meno abbienti e vulnerabili.
            </li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
