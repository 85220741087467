import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/2499757.jpg";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Partecipazione: democratica e aperta
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p>
            La CER cooperativa consente la partecipazione democratica alla
            produzione e al consumo di energia:
          </p>
          <ul>
            <li>
              la cooperativa è un’impresa democratica basata sul principio “una
              testa, un voto”;
            </li>
            <li>
              questo significa che in cooperativa non decide chi ha investito di
              più, ma tutti i soci hanno lo stesso peso decisionale,
              indipendentemente dal capitale investito;
            </li>
            <li>le persone sono al centro dei processi decisionali.</li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
