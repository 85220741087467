import React from "react";

import Assistant from "../components/Assistant";

function LateralMenu({ video, image, selected = 0 }) {
  const items = [
    "DEFINIZIONE",
    "LEGGE",
    "PARTECIPANTI",
    "FORMA COOPERATIVA",
    "SCAMBIO",
    "CONSUMI BUONI",
    "ORARI BUONI",
    "PROFILI",
    "UNICITÀ",
  ];

  return (
    <div
      style={{
        height: "100%",
        minWidth: "25%",
        paddingRight: 10,
        paddingLeft: 10,
        backgroundImage: `linear-gradient(#1DE9B6, #E6EE9C)`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <p
        style={{
          marginTop: "5%",
          marginBottom: 0,
          color: "white",
          fontSize: "3em",
          fontWeight: 300,
        }}
      >
        CER
      </p>
      <p
        style={{
          marginTop: 0,
          color: "#E6EE9C",
          fontSize: "1.1em",
          fontWeight: 600,
        }}
      >
        Comunità Energetica Rinnovabile
      </p>
      <ul id="menuCER">
        {items.map((item, index) =>
          index === selected ? (
            <li
              style={{
                color: "#00BFA5",
                backgroundColor: "white",
                borderRadius: 15,
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              {item}
            </li>
          ) : (
            <li>{item}</li>
          )
        )}
      </ul>
      <Assistant
        src={video}
        image={image}
        position={{
          zIndex: 10,
        }}
      />
    </div>
  );
}

export default LateralMenu;
