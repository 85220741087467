import { Box, Grid } from "@mui/material";
import React from "react";

function CardProgettoCoop({ bgimg, logo, logo_coop, text }) {
  return (
    <Box
      component={"div"}
      sx={{
        bgcolor: "#fff",
        borderRadius: "30px",
        height: "45vh",
        width: "250px",
        color: "#000",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        component={"div"}
        sx={{
          backgroundImage: `url(${bgimg})`,
          opacity: 0.2,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "45vh",
          width: "250px",
          color: "#000",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      ></Box>
      <Grid
        container
        sx={{
          height: "45vh",
          width: "250px",
          color: "#000",
          position: "absolute",
          zIndex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box height="50%" textAlign={"center"} alignContent={"center"}>
          <Box component={"img"} src={logo} width="80%" />
        </Box>
        <Box component={"div"} height="30%" textAlign={"center"}>
          <p style={{ fontSize: "1.5rem" }}>{text}</p>
        </Box>
        <Box height="20%" textAlign={"center"}>
          <Box component={"img"} src={logo_coop} width="80%" />
        </Box>
      </Grid>
    </Box>
  );
}

export default CardProgettoCoop;
