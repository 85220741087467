import { IconButton, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

function CustomModal({
  open,
  onClose = () => {},
  children,
  width = "600px",
  showCloseIcon = false,
}) {
  return (
    <Modal open={open} onClose={onClose} disableAutoFocus>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: width,
          backgroundColor: "#fff",
          border: "unset",
          borderRadius: 10,
          padding: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {children}
        {showCloseIcon && (
          <div style={{ position: "absolute", right: "1.5rem" }}>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CustomModal;
