import React from "react";
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";
import Assistant from "../components/Assistant";

import video from "../assets/video/Respira_e_Rec.mp4";
import CardProgettoCoop from "../components/CardProgettoCoop";
import bgimg1 from "../assets/ItalyMap.png";
import bgimg2 from "../assets/Umbria_map.jpg";
import logo1 from "../assets/logo_respira_nero.png";
import logo2 from "../assets/REC-Rete-Energetica-Cooperativa-logo.png";
import logocoop1 from "../assets/Legacoop_nuovo_logo_colore.png";
import logocoop2 from "../assets/logo_legacoop_Umbria.png";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.6,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

export default function P14_2() {
  return (
    <motion.div
      style={{
        height: "100%",
        backgroundImage: `linear-gradient(#1DE9B6, #E6EE9C)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        //gap: "2rem",
        flexDirection: "column",
      }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <motion.p
        variants={childVariants}
        style={{
          color: "white",
          fontSize: "3em",
          marginBottom: "1rem",
        }}
      >
        Legacoop per le Comunità
      </motion.p>
      <motion.div
        variants={childVariants}
        style={{
          width: "80%",
          margin: "auto",
          marginBottom: "1rem",
        }}
      >
        <p>
          Abbiamo attivato progetti e strumenti specifici per supportare
          cittadini, amministrazioni e imprese nella costruzione e avvio delle
          comunità energetiche in forma cooperativa:
        </p>
        <ul>
          <li>
            promozione del modello cooperativo di produzione e condivisione
            dell’energia rinnovabile;{" "}
          </li>
          <li>
            supporto nella costituzione della CER in forma cooperativa inclusa
            l’elaborazione dello statuto e del regolamento;{" "}
          </li>
          <li>
            ricerca di fondi e risorse per realizzare lo studio di fattibilità e
            per acquistare gli impianti;
          </li>
          <li>
            progettazione e installazione degli impianti per la produzione
            dell’energia rinnovabile.
          </li>
        </ul>
      </motion.div>
      <motion.div
        variants={childVariants}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "3rem",
        }}
      >
        <CardProgettoCoop
          bgimg={bgimg1}
          logo={logo1}
          logo_coop={logocoop1}
          text="www.respira.coop"
        />
        <CardProgettoCoop
          bgimg={bgimg2}
          logo={logo2}
          logo_coop={logocoop2}
          text="retecooperativa.it"
        />
      </motion.div>

      <motion.div variants={childVariants}>
        <Assistant
          src={video}
          position={{
            zIndex: 10,
            position: "absolute",
            bottom: "30px",
            left: "30px",
          }}
        />
      </motion.div>

      <Navigation back="/p14_1" forward="/p15" />
    </motion.div>
  );
}
